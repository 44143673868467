<template>
  <div>
    <el-collapse
      class="accordion"
      v-loading="isLoading"
      accordion>
      <div v-if="isEmptyResponse">{{ $t('global.noResults') }}</div>
      <el-collapse-item
        v-for="(item, index) in items"
        :key="index"
        :name="item.slug">
        <template slot="title">
          <span class="accordion__title">{{ item.title.rendered }}</span>
        </template>
        <div
          class="accordion__content"
          v-html="item.content.rendered">
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: [
    'items',
    'isLoading',
    'isEmptyResponse',
  ],
};
</script>
