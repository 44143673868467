<template>
  <div class="faqs__list">
    <el-collapse class="accordion" accordion>
      <div v-if="isEmptyResponse">{{ $t('global.noResults') }}</div>
      <Accordion
        :items="items"
        :isLoading="isLoading"
        :isEmptyResponse="isEmptyResponse">
      </Accordion>
    </el-collapse>
    <el-pagination
      background
      @current-change="handleCurrentPageChange"
      :hide-on-single-page="true"
      :current-page.sync="currentPage"
      :page-size="perPage"
      :total="itemsTotal"
      layout="prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
import { faqsApi } from '@/api';
import Accordion from '@/components/Accordion.vue';

export default {
  name: 'FaqIndex',
  data() {
    return {
      items: [],
      itemsTotal: 0,
      pagesTotal: 0,
      perPage: 6, // Default page post limit
      currentPage: 1,
      isLoading: false,
      isEmptyResponse: false,
      metaKey: 'tab',
    };
  },
  components: {
    Accordion,
  },
  created() {
    this.parseQueryFilters(this.$route.query);
    this.getRemoteItems(this.$route.params.section);
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryFilters(to.query);
    this.getRemoteItems(to.params.section);
    next();
  },
  methods: {
    async getRemoteItems(section) {
      try {
        this.isLoading = true;
        const params = {
          per_page: this.perPage,
          page: this.currentPage,
          meta_key: this.metaKey,
          meta_value: section || 'products',
        };
        const response = await faqsApi.getFaqs(params);
        this.isLoading = false;
        this.items = response.data;
        this.itemsTotal = parseInt(response.headers['x-wp-total'], 10);
        this.pagesTotal = parseInt(response.headers['x-wp-totalpages'], 10);
        this.isEmptyResponse = (this.items.length === 0);
      } catch (error) {
        this.isLoading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    handleCurrentPageChange(page) {
      this.$router.push({
        name: 'FaqIndex',
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    parseQueryFilters(query) {
      this.currentPage = query.page ? parseInt(query.page, 10) : 1;
    },
  },
};
</script>
